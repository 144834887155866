@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    /* Creates a smooth vertical gradient from the primary background color (0%) to the secondary background color (100%) */
    /* background: linear-gradient(180deg, var(--color-background) 0%, var(--color-background-secondary) 100%); */
    min-height: 100vh;
  }

  body {
    margin: 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-text);
    background: transparent;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
}

@layer components {
  .text-gradient {
    background: linear-gradient(
      135deg,
      var(--color-primary) 0%,
      var(--color-primary-light) 20%,
      var(--color-primary-dark) 40%,
      var(--color-secondary) 60%,
      var(--color-primary-light) 80%,
      var(--color-primary) 100%
    );
    background-size: 200% auto;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: gradient 8s ease infinite;
  }

  .glass {
    background: var(--color-surface);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid var(--color-divider);
  }

  .animate-gradient {
    animation: gradient 8s ease infinite;
    background-size: 200% auto;
  }
}

@layer utilities {
  .transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }

  .focus-visible:focus-visible {
    outline: 2px solid var(--color-primary);
    outline-offset: 2px;
  }
}

/* Custom scrollbar for dark theme */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: var(--color-background);
}

::-webkit-scrollbar-thumb {
  background: var(--color-primary-dark);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-primary);
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Selection color */
::selection {
  background-color: rgba(29, 185, 84, 0.3);
  color: #ffffff;
}

/* Input autofill background */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #181818 inset !important;
  -webkit-text-fill-color: #ffffff !important;
}
