.join-discord-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #7289da; /* Discord color */
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.join-discord-button:hover {
    background-color: #5b6eae; /* Darker shade on hover */
}